<template>
  <section class="image-gallery">
    <div class="container">
      <div class="row" v-if="typeof images == 'object'">
        <div
          class="col-12 col-md-6 img-grid"
          v-for="image of images.slides"
          :key="image.id"
        >
          <div
            class="img-holder"
            :style="{ backgroundImage: `url(${image.media.url})` }"
          ></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ImageGallery",
  computed: {
    images() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "home_gallery"
      );
    },
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.image-gallery {
  @include media("screen", ">=desktop") {
    padding: 15px 0;
  }
}

.img-holder {
  width: 100%;
  height: 393px;
  background-size: cover;
  background-position: 50% 50%;
  margin: 15px 0;

  @include media("screen", ">=desktop") {
    height: 553px;
  }
}

.img-grid {
  &:nth-child(n + 3) {
    display: none;

    @include media("screen", ">=tablet") {
      display: block;
    }
  }
}
</style>
