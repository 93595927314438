<template>
  <b-carousel
    id="carousel-1"
    v-model="slide"
    :interval="5000"
    indicators
    style="text-shadow: 1px 1px 2px #333"
    @sliding-start="onSlideStart"
    @sliding-end="onSlideEnd"
    class="hero-banner-carousel"
  >
    <b-carousel-slide
      v-for="(bSlide, index) in slider.slides"
      :key="index"
      :style="{ backgroundImage: `url(${bSlide.optImage})` }"
    >
      <div class="container">
        <span class="d-block text-uppercase text-light font-weight-normal">{{
          bSlide.title
        }}</span>
        <v-runtime-template
          v-if="bSlide.description != null"
          :template="bSlide.description"
        ></v-runtime-template>
        <b-link
          v-if="bSlide.buttonText != null"
          :to="$link(bSlide.link)"
          class="btn btn-dark ml-30 ml-md-0"
          >{{ bSlide.buttonText }}</b-link
        >
      </div>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import VRuntimeTemplate from "v-runtime-template";
import { isMobile } from "mobile-device-detect";
import { isTablet } from "mobile-device-detect";
import { isBrowser } from "mobile-device-detect";

export default {
  name: "HeroBanner",
  components: { VRuntimeTemplate },
  data: () => ({
    slide: 0,
    sliding: false,
  }),
  computed: {
    slider() {
      let slider = this.$store.getters["sliders/getSliderByIdentifier"]("home");

      if (slider.slides != null) {
        slider.slides.forEach((element) => {
          if (this.mobileCheck == "browser") {
            element.optImage = element.media.url;
          } else if (this.mobileCheck == "tablet") {
            element.optImage = element.media.large;
          } else {
            element.optImage = element.media.medium;
          }
        });
      }

      return slider;
    },
    mobileCheck() {
      if (isBrowser) {
        return "browser";
      }
      if (isMobile && isTablet) {
        return "tablet";
      }
      return "mobile";
    },
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.hero-banner-carousel {
  font-size: 35px;
  line-height: 1;

  @include media("screen", ">=desktop") {
    font-size: 40px;
  }
  .carousel-indicators {
    display: none;

    @include media("screen", ">=tablet") {
      display: flex;
    }

    li {
      width: 20px;
      height: 20px;
      border-radius: 100%;
      outline: none;
      bottom: 11px;
      margin: 0 5px;
      position: relative;

      &.active {
        &:before {
          content: "";
          width: 8px;
          height: 8px;
          background: #000;
          position: absolute;
          top: 6px;
          left: 6px;
          border-radius: 100%;
        }
      }
    }
  }
  .carousel-item {
    @include media("screen", "<tablet") {
      background-position: center;
    }
  }
  .carousel-caption {
    position: absolute;
    text-align: left;
    padding-top: 27px;
    padding-bottom: 27px;

    @include media("screen", ">=tablet") {
      padding-top: 186px;
      padding-bottom: 186px;
    }
  }

  .btn {
    font-size: 20px;
    min-width: 213px;
    font-weight: 300;

    &:hover {
      color: white !important;
      background: lighten(black, 40%);
    }
  }

  .container {
    @include media("screen", ">=phone") {
      padding-left: 15px;
    }
  }
  .discount-value {
    font-size: 176px;
    font-weight: 600;

    @include media("screen", ">=tablet") {
      font-size: 200px;
    }
  }

  .discount-percent {
    font-size: 88px;

    @include media("screen", ">=tablet") {
      font-size: 100px;
    }
  }

  .discount-offer {
    font-size: 80px;

    @include media("screen", ">=tablet") {
      font-size: 90px;
    }
  }

  .carousel-item {
    height: 50vh;
    @include media("screen", ">=desktop") {
      height: 760px;
    }
    @include media("retina2x", ">=desktop") {
      height: 75vh;
    }
    background-size: cover;
  }
}
</style>
