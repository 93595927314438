<template>
  <section class="our-stores">
    <div class="container" v-if="storeImages != null">
      <span class="section-heading font-weight-normal text-center d-block">{{
        $t("dt1stopengo")
      }}</span>
      <div class="row">
        <div
          class="col-12"
          v-for="(storeImage, index) in storeImages"
          :key="`stores-${index}`"
        >
          <div v-if="storeImage.base_image != null" class="img-holder">
            <video width="1150" height="647" controls v-if="videoUrl">
              <source :src="videoUrl" />
            </video>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import config from "@config";
export default {
  name: "OurStores",
  data: () => ({}),
  computed: {
    storeImages() {
      return this.$store.getters["stores/getStores"];
    },
    videoUrl() {
      return config.shop.url + "/media/video/DT1_Cairoli_30SEC_V3.mp4";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.img-holder {
  width: 100%;
  background-size: cover;
  background-position: 50% 50%;
  margin: 15px 0;
  video {
    @include media("screen", "<1150px") {
      max-width: 100%;
    }
  }
  height: auto;
}

.section-heading {
  font-size: 15px;
  margin-bottom: 5px;

  @include media("screen", ">=desktop") {
    margin-bottom: 3px;
    font-size: 20px;
  }
}

.our-stores {
  padding: 5px 0;
  @include media("screen", ">=desktop") {
    padding: 15px 0;
  }
}
</style>
