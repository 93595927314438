<template>
  <ul class="normal">
    <li v-for="icon of icons" :key="icon.iconNumber">
      <b-link
        v-if="icon.isEnabled && icon.linkType != 'mailto'"
        :to="icon.link"
        @click.prevent="onClick"
        v-b-toggle="`topIconsCollapse` + showType + icon.iconNumber"
      >
        <font-awesome-icon
          v-if="icon.isLinearIcon"
          class="icons"
          :icon="icon.iconName"
        ></font-awesome-icon>
        <div class="img" v-else>
          <b-img
            :src="icon.iconName"
            width="25"
            height="25"
            :alt="icon.text"
            fluid
          ></b-img>
        </div>
        <span>{{ icon.text }}</span>
        <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{
          icon.badgeValue
        }}</b-badge>
      </b-link>
      <a
        :href="icon.link"
        v-if="icon.isEnabled && icon.linkType == 'mailto'"
        @click.prevent="onClick"
        v-b-toggle="`topIconsCollapse` + showType + icon.iconNumber"
      >
        <font-awesome-icon
          v-if="icon.isLinearIcon"
          class="icons"
          :icon="icon.iconName"
        ></font-awesome-icon>
        <div class="img" v-else>
          <b-img
            :src="icon.iconName"
            width="25"
            height="25"
            :alt="icon.text"
            fluid
          ></b-img>
        </div>
        <span>{{ icon.text }}</span>
        <b-badge v-if="icon.hasBadge" :variant="icon.badgeColor">{{
          icon.badgeValue
        }}</b-badge></a
      >
      <HeaderIconsDropdownCart
        v-if="
          icon.hasDropdown && icon.menuId == 'cart' && icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdown
        v-if="
          icon.hasDropdown &&
          icon.menuId != 'cart' &&
          icon.menuId != 'account' &&
          icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
      <HeaderIconsDropdownMenu
        v-if="
          icon.hasDropdown && icon.menuId == 'account' && icon.isEnabled == true
        "
        :icon="icon"
        :showType="showType"
      />
    </li>
  </ul>
</template>
<script>
//import { mapGetters } from "vuex";
import HeaderIconsDropdown from "@/esf_lommel_dt1_europe/core/components//HeaderIconsDropdown";
import HeaderIconsDropdownCart from "@/esf_lommel_dt1_europe/core/components//HeaderIconsDropdownCart";
import HeaderIconsDropdownMenu from "@/esf_lommel_dt1_europe/core/components//HeaderIconsDropdownMenu";
//import wishListAdapter from "@/esf_lommel_dt1_europe/helpers/adapters/wishListAdapter";
import Cart from "@storefront/core/modules/cart/mixins";
import {
  faMapMarkerAlt,
  faInfoCircle,
  faShoppingCart,
  faHeart,
  faUser,
  faGlobeEurope,
} from "@fortawesome/free-solid-svg-icons";
import nl from "@/esf_lommel_dt1_europe/assets/nl.svg";
import en from "@/esf_lommel_dt1_europe/assets/gb.svg";

export default {
  name: "HeaderTopIcons",
  mixins: [Cart],
  components: {
    HeaderIconsDropdown,
    HeaderIconsDropdownCart,
    HeaderIconsDropdownMenu,
  },
  methods: {
    onClick() {},
  },
  props: {
    showType: { type: String },
  },
  computed: {
    icons() {
      return [
        {
          iconNumber: 1,
          isLinearIcon: true,
          hasBadge: false,
          iconName: faMapMarkerAlt,
          text: this.$t("Become a dealer"),
          hasDropdown: false,
          link: "mailto:info@dt1-europe.com",
          isEnabled: true,
          linkType: "mailto",
        },
        {
          iconNumber: 2,
          isLinearIcon: true,
          hasBadge: false,
          iconName: faInfoCircle,
          text: this.$t("customer_service"),
          hasDropdown: false,
          link: this.$link("/" + this.$t("menu_contact")),
          isEnabled: true,
          linkType: "normal",
        },
        {
          iconNumber: 3,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.cartCount,
          badgeColor: "success",
          iconName: faShoppingCart,
          text: this.$t("shoppping_basket"),
          isEnabled: this.isLoggedIn,
          hasDropdown: true,
          dropHeading: this.$t("items_in_cart", { numberOf: this.cartCount }),
          dropItems: this.cartItems,
          menuId: "cart",
          cartFooter: {
            totalPrice: this.cartSubTotalPrice,
            btnText: this.$t("continue_buying"),
            btnVariant: "success",
          },
          link: "#",
          linkType: "normal",
        },
        {
          iconNumber: 4,
          isLinearIcon: true,
          hasBadge: true,
          badgeValue: this.wishlistCount,
          badgeColor: "dark",
          iconName: faHeart,
          text: this.$t("wishlist"),
          hasDropdown: true,
          menuId: "wishList",
          isEnabled: this.isLoggedIn,
          dropHeading: this.$t("items_in_wishlist", {
            numberOf: this.wishlistCount,
          }),
          dropItems: this.wishlistItems,
        },
        {
          iconNumber: 5,
          isLinearIcon: true,
          hasBadge: false,
          iconName: faUser,
          text: this.$t("account"),
          hasDropdown: false,
          link: this.$link("/account"),
          isEnabled: !this.isLoggedIn,
          linkType: "normal",
        },
        {
          iconNumber: 6,
          isLinearIcon: true,
          hasBadge: false,
          iconName: faUser,
          text: this.$t("account"),
          menuId: "account",
          hasDropdown: true,
          isEnabled: this.isLoggedIn,
          linkType: "normal",
        },
        {
          iconNumber: 7,
          isLinearIcon: true,
          hasBadge: false,
          iconName: faGlobeEurope,
          text: this.$t("language"),
          hasDropdown: true,
          isEnabled: true,
          menuId: "language",
          language: [
            {
              img: en,
              alt: "English",
              href: "/en",
            },
            {
              img: nl,
              alt: "Nederlands",
              href: "/",
            },
          ],
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.img {
  width: 25px;
  margin: 0 auto;
  padding-bottom: 5px;

  img {
    width: 100%;
  }
}
.icons {
  font-size: 25px;
  z-index: -99;
}
.oppesit {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0 17px;
  font-size: 12px;
  padding: 0;
  position: relative;

  @include media("screen", ">=desktop") {
    padding: 0;
    margin-left: 22px;
  }

  i {
    display: block;
    font-size: 25px;
    padding-bottom: 6px;
    outline: none;
    position: relative;
    z-index: -2;
  }

  > li {
    text-align: center;
    font-weight: 400;
    position: relative;
    + li {
      margin-left: 11px;

      @include media("screen", ">=desktop") {
        margin-left: 17px;
      }
    }
  }

  a {
    position: relative;
    color: black;
    display: block;
    &:hover {
      color: gray !important;
    }
  }

  span {
    display: none;

    @include media("screen", ">=desktop") {
      display: block;
    }
  }
}

.normal {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 0 17px;
  font-size: 12px;
  padding: 0;
  position: relative;

  @include media("screen", ">=desktop") {
    padding: 0;
    margin-left: 22px;
  }

  i {
    display: block;
    font-size: 25px;
    padding-bottom: 6px;
    outline: none;
    position: relative;
    z-index: -2;
  }

  > li {
    text-align: center;
    font-weight: 400;
    position: relative;
    + li {
      margin-left: 11px;

      @include media("screen", ">=desktop") {
        margin-left: 17px;
      }
    }
  }

  a {
    position: relative;
    color: #000;
    display: block;
    &:hover {
      color: gray !important;
    }
  }

  span {
    display: none;

    @include media("screen", ">=desktop") {
      display: block;
    }
  }
}

.badge {
  position: absolute !important;
  display: block !important;
  top: -5px;
  left: calc(50% + 11px);
  z-index: 1;
  // right: 4px;
  transform: translateX(-50%);
  font-family: "Work Sans", sans-serif;
  pointer-events: none;
  background-color: #00893d;

  @include media("screen", ">=desktop") {
    top: -10px;
    left: calc(50% + 13px);
  }
}
</style>
