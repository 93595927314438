<template>
  <div>
    <span class="mobile-menu-header d-lg-none">{{
      $t("navbar_choose_category")
    }}</span>
    <b-link class="nav-opener inner-close" @click="toggleNav"></b-link>
    <ul class="nav-menu">
      <li class="nav-item">
        <b-link :to="$link('/')">{{ $t("home") }}</b-link>
      </li>
      <li
        class="nav-item"
        v-for="navItem of navItems"
        :key="navItem.id"
        @mouseover="onOver(navItem.id)"
        @mouseleave="onLeave(navItem.id)"
      >
        <div class="nav-link" @click="onToggle(navItem.id)">
          <b-link
            v-if="navItem.linkTo != '#'"
            :to="$link(navItem.linkTo)"
            @click="$store.commit('menu/setNavBar', false)"
            >{{ navItem.itemName }}</b-link
          >
        </div>

        <HeaderSubmenu
          v-if="navItem.hasDropMenu"
          :menuArray="navItem.dropMenu"
          :collapseId="'submenu' + navItem.id"
          :id="navItem.id"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { Logger } from "@storefront/core/lib/logger";
import HeaderSubmenu from "@/esf_lommel_dt1_europe/core/components//HeaderSubmenu";
export default {
  name: "NavbarContentHome",
  components: {
    HeaderSubmenu,
  },
  data: () => ({}),
  computed: {
    navItems() {
      return this.$store.getters["menu/getMenuItems"];
    },
  },

  methods: {
    onOver(id) {
      this.$store.commit("menu/updateMenuState", { id: id, state: true });
    },
    onToggle(id) {
      const vis = this.$store.getters["menu/getMenuItemState"](id);
      if (vis.state == false) {
        this.$store.commit("menu/updateMenuState", { id: id, state: true });
      } else {
        this.$store.commit("menu/updateMenuState", { id: id, state: false });
      }
    },
    onLeave(id) {
      this.$store.commit("menu/updateMenuState", { id: id, state: false });
    },
    onClick() {},
    doMouseOver(id) {
      Logger.debug("Mouse Over", "Menu", id)();
    },
    toggleNav(event) {
      this.$emit("clicked", event);
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/esf_lommel_dt1_europe/scss/main.scss";
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";
@import "@/esf_lommel_dt1_europe/scss/mixins.scss";

.nav-menu {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;

  @include media("screen", ">=desktop") {
    margin: 0 -8px;
    padding: 8px 0;
    padding-top: 0;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }

  @include media("screen", ">=widescreen") {
    margin: 0 -16px;
  }

  li {
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    @include media("screen", ">=desktop") {
      border: none;
      margin: 3px 8px;
    }

    @include media("screen", ">=widescreen") {
      margin: 0 16px;
    }

    .nav-link {
      padding-top: 6px;
      padding-bottom: 10px;
      padding-right: 20px;
      position: relative;

      @include media("screen", ">=desktop") {
        padding: 0;
      }

      &:after {
        @include media("screen", "<desktop") {
          content: "\e874";
        }
        font-family: "Linearicons-Free";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        font-size: 13px;
      }

      &.collapsed {
        color: #000;
      }

      &.not-collapsed {
        &:after {
          content: "\e873";
          @include media("screen", ">=desktop") {
            content: none;
          }
        }
      }
    }
  }
  a {
    color: black;
    &:hover {
      color: gray !important;
    }
  }

  .oppesit {
    color: black;
    display: block;
    &:hover {
      color: gray !important;
    }
  }
}

.nav-opener {
  display: block;
  border: none;
  outline: none !important;
  padding: 0;

  width: 32px;
  height: 22px;
  position: relative;
  z-index: 0;

  @include media("screen", ">=desktop") {
    display: none;
  }

  &:before,
  &:after,
  span {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background: #000;
    @include animate(transform opacity top);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  span {
    top: 50%;
    transform: translateY(-50%);
  }

  &.inner-close {
    position: absolute;
    top: 22px;
    right: 15px;
    width: 24px;

    &:before,
    &:after,
    span {
      height: 0.14rem;
    }

    &:before {
      transform: rotate(45deg);
      top: 9px;
      background-color: black;
    }
    &:after {
      transform: rotate(-45deg);
      top: 9px;
      background-color: black;
    }

    span {
      opacity: 0;
    }
  }
}

.nav-item {
  position: relative;
  .sub-menu {
    top: 100%;
  }
  &.active {
    color: #ff8000 !important;

    .nav-link.collapsed {
      color: inherit !important;
    }
  }

  + .nav-item {
    @include media("screen", "<desktop") {
      margin-top: -1px;
    }
  }

  .collapsed {
    color: #000 !important;

    &:hover {
      @include media("screen", ">=tablet") {
        color: #ff8000 !important;
      }
    }

    &:focus {
      color: #000 !important;
    }
  }

  .not-collapsed {
    position: relative;

    @include media("screen", ">=desktop") {
      color: #000 !important;
    }
    &:before {
      content: none;
      position: absolute;
      top: calc(100% + 6px);
      left: -7px;
      right: -7px;
      height: 3px;
      background: #ff8000;

      @include media("screen", ">=desktop") {
        content: "";
      }
    }
    &:hover {
      @include media("screen", "<desktop") {
        color: #000 !important;
      }
    }
  }

  .sub-menu {
    @include media("screen", "<desktop") {
      border-top: 1px solid #e6e6e6;
    }
  }

  &:nth-child(n + 8) {
    .sub-menu {
      right: -8px;
      left: auto;
    }
  }
}

.mobile-menu-header {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #111f44;
  margin-bottom: 18px;
}
</style>
