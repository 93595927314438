<template>
  <BaseLayoutHome>
    <!-- <DiscountCode /> -->
    <FilterSystem />
    <HeroBanner />
    <!-- <BundlesCarousel /> -->
    <ImageGallery />
    <SubscribeSeparator />
    <OurStores />
  </BaseLayoutHome>
</template>

<script>
import BaseLayoutHome from "@/esf_lommel_dt1_europe/core/components//BaseLayoutHome";

// import DiscountCode from "@/esf_lommel_dt1_europe/core/components//DiscountCode";
import HeroBanner from "@/esf_lommel_dt1_europe/core/components//HeroBanner";
import FilterSystem from "@/esf_lommel_dt1_europe/core/components//FilterSystem";
//import BundlesCarousel from "@/esf_lommel_dt1_europe/core/components//BundlesCarousel";
import ImageGallery from "@/esf_lommel_dt1_europe/core/components//ImageGallery";
import SubscribeSeparator from "@/esf_lommel_dt1_europe/core/components//SubscribeSeparator";
import OurStores from "@/esf_lommel_dt1_europe/core/components//OurStores";
export default {
  name: "HomePage",
  components: {
    BaseLayoutHome,
    // DiscountCode,
    HeroBanner,
    FilterSystem,
    //BundlesCarousel,
    ImageGallery,
    SubscribeSeparator,
    OurStores,
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
