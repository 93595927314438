<template>
  <section class="separator text-light">
    <div class="container">
      <div
        class="d-flex flex-column flex-lg-row align-items-center justify-content-center bg-dark px-20 px-md-10 pl-xl-20"
      >
        <div class="d-flex align-items-center mb-15 mb-lg-0">
          <div class="icon">
            <img src="@/esf_lommel_dt1_europe/assets/email-icon.png" alt="email" />
          </div>
          <div class="text-center">
            <span class="d-block">{{ $t("newsletter_advantage") }}</span>
            <span class="d-block">{{ $t("receive_newsletter") }}</span>
          </div>
        </div>
        <form class="subscribe-form d-flex align-items-center">
          <input
            v-model="email"
            type="email"
            id="subscribe--email"
            :placeholder="$t('newsletter_placeholder')"
            class="text-dark"
            aria-label="Email"
          />
          <button
            type="button"
            @click="subscribe(email)"
            class="btn subscribe-button"
          >
            {{ $t("subscribe_newsletter") }}
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import User from "@storefront/core/modules/user/mixins";
export default {
  name: "SubscribeSeparator",
  mixins: [User],
  data() {
    return { email: "" };
  },
};
</script>

<style lang="scss" scoped>
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
.subscribe-button {
  background-color: #1d71b8;
  color: white;
}
.separator {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  background-image: url("@/esf_lommel_dt1_europe/assets/Cyclewearonline-dl.svg");
  background-size: auto 100%;
  background-position: 0 0;
  margin-top: 30px;
  margin-bottom: 30px;

  @include media("screen", ">=desktop") {
    padding: 2px 0;
    margin-top: 42px;
    margin-bottom: 42px;
  }

  &:before {
    content: "";
    position: absolute;
    left: -9999px;
    right: -9999px;
    top: 0;
    bottom: 0;
  }

  .bg-dark {
    display: inline-block;
    position: relative;
    z-index: 0;
    line-height: 1.35;
    margin-left: 11px;
    margin-right: 12px;
    padding: 20px 2px 28px;

    @include media("screen", ">=desktop") {
      padding: 0;
    }

    &:before {
      content: "";
      position: absolute;
      left: -11px;
      right: -12px;
      top: -3px;
      bottom: -3px;
      background: white;
      z-index: -2;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: -2px;
      bottom: -2px;
      background: black;
      z-index: -1;

      @include media("screen", ">=desktop") {
        left: -1px;
        right: -2px;
      }
    }
  }

  .icon {
    width: 60px;
    margin-right: 27px;

    img {
      width: 100%;
    }
  }
}

form {
  display: flex;
  width: 100%;

  @include media("screen", ">=desktop") {
    width: auto;
  }

  input[type="email"] {
    font-size: 12px;
    padding: 8px 14px;
    border-radius: 0;
    border: none;
    outline: none;
    font-family: "Work Sans", sans-serif;
    flex-grow: 1;

    @include media("screen", ">=desktop") {
      flex-grow: 0;
      width: 400px;
      margin-left: 36px;
    }
  }

  .btn {
    background: #00893d;
    padding: 4px 15px;

    @include media("screen", ">=desktop") {
      margin-left: 8px;
      padding: 4px 18px;
    }
  }
}
</style>
