<template>
  <nav id="nav">
    <ul class="nav-opener-icon">
      <li>
        <font-awesome-icon class="icons" :icon="iconName" @click="toggleNav">
        </font-awesome-icon>
      </li>
    </ul>
    <div
      class="nav-overlay"
      @click="toggleNav"
      :class="{ show: navExpanded }"
    ></div>
    <div class="fake-nav-overlay" :class="{ show: navExpanded }"></div>
    <transition name="slide-fade">
      <div class="nav-collapse" :class="{ show: navExpanded }">
        <NavbarContentHome @clicked="toggleNav" />
      </div>
    </transition>
  </nav>
</template>

<script>
import NavbarContentHome from "./NavbarContentHome.vue";
import { faAlignJustify } from "@fortawesome/free-solid-svg-icons";
export default {
  name: "NavbarHome",
  components: {
    NavbarContentHome,
  },
  data: () => ({ iconName: faAlignJustify }),
  computed: {
    navExpanded: {
      get() {
        return this.$store.getters["menu/getNavbar"];
      },
      set() {},
    },
  },

  mounted() {
    window.addEventListener("resize", this.resize);
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  methods: {
    onClick() {},
    toggleNav() {
      this.$store.dispatch("menu/toggleNavbar");
    },
    resize() {
      document.body.classList.add("resize-active");
      if (window.innerWidth > 991) this.navExpanded = false;
      clearTimeout(this.resizeTimer);
      this.resizeTimer();
    },
    resizeTimer() {
      setTimeout(() => {
        document.body.classList.remove("resize-active");
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
//@import "@/esf_lommel_dt1_europe/scss/main.scss";
@import "@/esf_lommel_dt1_europe/scss/media-queries.scss";
@import "@/esf_lommel_dt1_europe/scss/custom-bootstrap.scss";
@import "@/esf_lommel_dt1_europe/scss/mixins.scss";
@import url("https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");

.img {
  width: 25px;
  margin: 0 auto;
}

.icons {
  color: white;
  cursor: pointer;
  font-size: 25px;
  z-index: -99;
  margin-top: 10px;
}

.normal {
  color: #000;
  display: block;
  &:hover {
    color: gray !important;
  }
}

.oppesit {
  color: black;
  display: block;
  &:hover {
    color: gray !important;
  }
}

li {
  list-style-type: none;
}
#nav {
  padding: 15px 0;

  @include media("screen", ">=desktop") {
    padding: 0;
  }
}

.resize-active * {
  transition: none !important;
}

.nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  visibility: hidden;
  z-index: 222;
  @include animate(opacity visibility);
  transition-delay: 0.3s;

  &.show {
    transition-delay: 0;
    visibility: visible;
    opacity: 0.9;
  }
}

.fake-nav-overlay {
  @include media("screen", "<desktop") {
    transition: all 0.3s ease 0.3s;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background: white;
    z-index: 99999;
    width: 90%;
    max-width: 370px;
    transform: translateX(-100%);
  }

  &.show {
    @include media("screen", "<desktop") {
      transition: all 0.5s ease;
      transform: translateX(0);
    }
  }
}

.nav-collapse {
  @include animate(opacity visibility);
  transition-delay: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 90%;
  max-width: 375px;
  overflow-y: auto;
  background: white;
  padding: 19px 19px 19px 14px;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;

  @include media("screen", ">=desktop") {
    position: static;
    width: auto;
    max-width: none;
    overflow: visible;
    padding: 0;
    background: none;
    visibility: visible;
    opacity: 1;
  }

  &.show {
    transition-delay: 0.4s;
    visibility: visible;
    opacity: 1;
  }
}

.nav-opener-icon {
  display: block;
  border: none;
  outline: none !important;
  padding: 0;
  z-index: 0;

  @include media("screen", ">=desktop") {
    display: none;
  }
}
</style>
